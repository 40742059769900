@contentBackground: #f7f7f7;

.content-wrapper {
    background-color: @contentBackground;
}

.font-style-italic {
    font-style: italic;
}

.font-weight-normal {
    font-weight: normal;
}

.table-small th, .table-small td {
    font-size: 85%;
}

.table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th {
    border-top: 1px solid #ddd;
}

.ib-container.v-align-middle > * {
    vertical-align: middle;
}
.ib-container > * {
    display: inline-block !important;
}

/* ========================= SPACING ========================= */
@pixels_xs:  5px;
@pixels_sm: 10px;
@pixels_md: 20px;
@pixels_lg: 30px;

.p-0  { padding: 0 !important; }
.p-xs { padding: @pixels_xs !important; }
.p-sm { padding: @pixels_sm !important; }
.p-md { padding: @pixels_md !important; }
.p-lg { padding: @pixels_lg !important; }

.p-t-0  { padding-top: 0 !important; }
.p-t-xs { padding-top: @pixels_xs !important; }
.p-t-sm { padding-top: @pixels_sm !important; }
.p-t-md { padding-top: @pixels_md !important; }
.p-t-lg { padding-top: @pixels_lg !important; }

.p-l-0  { padding-left: 0 !important; }
.p-l-xs { padding-left: @pixels_xs !important; }
.p-l-sm { padding-left: @pixels_sm !important; }
.p-l-md { padding-left: @pixels_md !important; }
.p-l-lg { padding-left: @pixels_lg !important; }

.p-r-0  { padding-right: 0 !important; }
.p-r-xs { padding-right: @pixels_xs !important; }
.p-r-sm { padding-right: @pixels_sm !important; }
.p-r-md { padding-right: @pixels_md !important; }
.p-r-lg { padding-right: @pixels_lg !important; }

.p-b-0  { padding-bottom: 0 !important; }
.p-b-xs { padding-bottom: @pixels_xs !important; }
.p-b-sm { padding-bottom: @pixels_sm !important; }
.p-b-md { padding-bottom: @pixels_md !important; }
.p-b-lg { padding-bottom: @pixels_lg !important; }

.p-x-0  { padding-left: 0 !important; padding-right: 0 !important; }
.p-x-xs { padding-left: @pixels_xs !important; padding-right: @pixels_xs !important; }
.p-x-sm { padding-left: @pixels_sm !important; padding-right: @pixels_sm !important; }
.p-x-md { padding-left: @pixels_md !important; padding-right: @pixels_md !important; }
.p-x-lg { padding-left: @pixels_lg !important; padding-right: @pixels_lg !important; }

.p-y-0  { padding-top: 0 !important; padding-bottom: 0 !important; }
.p-y-xs { padding-top: @pixels_xs !important; padding-bottom: @pixels_xs !important; }
.p-y-sm { padding-top: @pixels_sm !important; padding-bottom: @pixels_sm !important; }
.p-y-md { padding-top: @pixels_md !important; padding-bottom: @pixels_md !important; }
.p-y-lg { padding-top: @pixels_lg !important; padding-bottom: @pixels_lg !important; }

.m-0  { margin: 0 !important; }
.m-xs { margin: @pixels_xs !important; }
.m-sm { margin: @pixels_sm !important; }
.m-md { margin: @pixels_md !important; }
.n-lg { margin: @pixels_lg !important; }

.m-t-0  { margin-top: 0 !important; }
.m-t-xs { margin-top: @pixels_xs !important; }
.m-t-sm { margin-top: @pixels_sm !important; }
.m-t-md { margin-top: @pixels_md !important; }
.m-t-lg { margin-top: @pixels_lg !important; }

.m-l-0  { margin-left: 0 !important; }
.m-l-xs { margin-left: @pixels_xs !important; }
.m-l-sm { margin-left: @pixels_sm !important; }
.m-l-md { margin-left: @pixels_md !important; }
.m-l-lg { margin-left: @pixels_lg !important; }

.m-r-0  { margin-right: 0 !important; }
.m-r-xs { margin-right: @pixels_xs !important; }
.m-r-sm { margin-right: @pixels_sm !important; }
.m-r-md { margin-right: @pixels_md !important; }
.m-r-lg { margin-right: @pixels_lg !important; }

.m-b-0  { margin-bottom: 0 !important; }
.m-b-xs { margin-bottom: @pixels_xs !important; }
.m-b-sm { margin-bottom: @pixels_sm !important; }
.m-b-md { margin-bottom: @pixels_md !important; }
.m-b-lg { margin-bottom: @pixels_lg !important; }

.m-x-0  { margin-left: 0 !important; margin-right: 0 !important; }
.m-x-xs { margin-left: @pixels_xs !important; margin-right: @pixels_xs !important; }
.m-x-sm { margin-left: @pixels_sm !important; margin-right: @pixels_sm !important; }
.m-x-md { margin-left: @pixels_md !important; margin-right: @pixels_md !important; }
.m-x-lg { margin-left: @pixels_lg !important; margin-right: @pixels_lg !important; }

.m-y-0  { margin-top: 0 !important; margin-bottom: 0 !important; }
.m-y-xs { margin-top: @pixels_xs !important; margin-bottom: @pixels_xs !important; }
.m-y-sm { margin-top: @pixels_sm !important; margin-bottom: @pixels_sm !important; }
.m-y-md { margin-top: @pixels_md !important; margin-bottom: @pixels_md !important; }
.m-y-lg { margin-top: @pixels_lg !important; margin-bottom: @pixels_lg !important; }
/* ========================= END SPACING ========================= */
