@customerPrimary: #00573d;

@topNavColor: #fff;
@topNavTextColor: @customerPrimary;
@leftNavColor: @customerPrimary;
@leftNavSubColor: lighten(color(@customerPrimary), 4%);
@userInfoBackgroundColor: @customerPrimary;
@navigationSubColor: darken(color(@customerPrimary), 9%);

.skin-blue .main-header .logo, .skin-blue .main-header .logo:hover {
    background-color: @topNavColor;
    color: @topNavTextColor;
}

.skin-blue .main-header .navbar {
    background-color: @topNavColor;
}

.skin-blue .main-header .navbar .nav>li>a,
.navbar-custom-menu .navbar-nav.nav-header-button,
.navbar-nav.submit-help {
    color: @topNavTextColor !important;
}

.skin-blue .left-side, .skin-blue .main-sidebar, .skin-blue .wrapper {
    background-color: @leftNavColor;
}

.skin-blue .sidebar-menu>li.active>app-nav-menu-item>a, .skin-blue .sidebar-menu>li:hover>app-nav-menu-item>a {
    background-color: darken(color(@leftNavColor), 5%);
}

.skin-blue .sidebar-menu>li>.treeview-menu {
    background-color: @leftNavSubColor;
}

/* "NAVIGATION" menu item */
.skin-blue .sidebar-menu>li.header {
    background-color: @navigationSubColor;
}

/* small logo */
.main-header .logo .logo-img {
    padding-top: 0;
    width: 25px;
}

.skin-blue .main-header li.user-header {
    background-color: @userInfoBackgroundColor;
}

/* hide the name of the app */
.main-header > .logo > .logo-lg > b {
    display: none;
}
