/* You can add global styles to this file, and also import other style files */
// @import "styles/_lib";
// @import "styles/_buttons";
// @import "styles/_cards";
// @import "styles/_styles";
@import "styles/base";
@import "styles/nav";

// TODO CCC: figure out better way to do these styles
tr.archived {
    background-color: rgb(255, 192, 203) !important;
}
tr.archived > td {
    background-color: rgb(255, 192, 203) !important;
}

div.form-check {
    display: inline-block !important;
}

.dropdown-menu {
    bottom: initial !important;
    margin-bottom: initial !important;
}
